import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css"; //! mandatory
import "./customToastContainer.scss";

interface CustomToastContainerProps {}

const CustomToastContainer: React.FunctionComponent<CustomToastContainerProps> = () => {
  return (
    <ToastContainer
      autoClose={3000}
      position="top-right"
      hideProgressBar
      closeOnClick={false}
      className="toast-container-root"
      toastClassName="toast-simple"
      bodyClassName="toast-body"
      closeButton={false}
    />
  );
};

export { CustomToastContainer };
