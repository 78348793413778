import { ErrorHandlerName, IErrorHandler } from "../../../Libs/xhr/ErrorHandler";
import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { Lazy } from "../../Helpers/Lazy";
import { I18nService, I18nServiceName } from "../../Services/I18n/I18nService";
import { getDialogService, IDialogService } from "../../Services/Interfaces/IDialogService";
import { getNavigationService, INavigationService } from "../../Services/Interfaces/INavigationService";
import { getToastService, IToastService } from "../../Services/Interfaces/IToastService";
import { getLogService, ILogService } from "../../Services/LogService";
import { IoC } from "../../Services/ServicesContainer";
import { BaseViewModel, BaseViewState } from "./BaseViewModel";

export class BaseAppViewModel<T extends BaseViewState> extends BaseViewModel<T> {
  //* Services
  protected readonly navigationService: Lazy<INavigationService>;
  protected readonly toastService: Lazy<IToastService>;
  protected readonly dialogService: Lazy<IDialogService>;
  protected readonly i18n: Lazy<I18nService>;
  protected readonly logService: Lazy<ILogService>;
  protected readonly errorHandler: Lazy<IErrorHandler>;

  //* Others
  protected cts?: XHRRequestCanceler;

  constructor(initialState: T, onStateUpdate?: (newState: T) => void) {
    super(initialState, onStateUpdate);
    this.navigationService = getNavigationService();
    this.toastService = getToastService();
    this.dialogService = getDialogService();
    this.i18n = IoC.getLazy<I18nService>(I18nServiceName);
    this.logService = getLogService();
    this.errorHandler = IoC.getLazy<IErrorHandler>(ErrorHandlerName);
  }

  public async WrapError(
    execAction: (cts: XHRRequestCanceler) => Promise<void>,
    execParams?: {
      customErrorHandler?: (error: any) => void;
      withBusy?: boolean;
      busyField?: keyof T;
    }
  ): Promise<void> {
    const { customErrorHandler, withBusy, busyField = "busy" } = execParams ?? {
      customErrorHandler: undefined,
      withBusy: false,
      busyField: undefined,
    };

    try {
      this.setState({ ...this.state, [busyField]: withBusy });
      this.cts = new XHRRequestCanceler();
      await execAction(this.cts);
      this.setState({ ...this.state, [busyField]: false });
    } catch (error) {
      this.setState({ ...this.state, [busyField]: false });
      if (customErrorHandler) {
        customErrorHandler(error);
      } else {
        if (!this.errorHandler.value().handle(error)) {
          //TODO: do something like reject error or throw error to caller
        }
      }
    }
  }

  protected getString(localeMessageId: string): string {
    return this.i18n.value().getString(localeMessageId);
  }

  protected disposeManagedObject(): void {
    super.disposeManagedObject();
    this.cts?.cancel();
  }
}
