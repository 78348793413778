import { Ability } from "@casl/ability";
import { MeInfo } from "../../../Models/Me/Me";

export enum MeActionType {
  SET_INITIALIZED = "app/me/set/initialized",
  SET_INFO = "app/me/set/info",
  SET_ABILITY = "app/me/set/ability",
}

export interface SetInitializedAction {
  type: typeof MeActionType.SET_INITIALIZED;
  payload: { initialized: boolean };
}

export interface SetInfoAction {
  type: typeof MeActionType.SET_INFO;
  payload: { info?: MeInfo };
}

export interface SetAbilityAction {
  type: typeof MeActionType.SET_ABILITY;
  payload: { ability: Ability; updaterName: string };
}

export type MeAction = SetInitializedAction | SetInfoAction | SetAbilityAction;
