import { Route } from "react-router";
import { AppRoutes } from "../../../Core/App/AppRoutes";
import { AppRouteType } from "../../Components/Templates/AppRouter/AppRouteType";
import { SectionListPage } from "../../Pages/Section/SectionListPage";

const sectionListRoute: AppRouteType = {
  id: AppRoutes.SECTION,
  render: Route,
  props: {
    exact: false,
    path: AppRoutes.SECTION,
    component: SectionListPage,
  },
};

export const getSectionRoutes = () => [sectionListRoute];
