import { IoC } from "../ServicesContainer";

export interface ToastActionOptions {
  actionTitle: string;
  action?: () => void;
  hideToastActionTrigger?: () => void;
}

export interface DownloadOptions {
  fileName: string;
  content: string;
  success?: boolean;
  failed?: boolean;
}

export interface IToastService {
  showSuccess(content: string): React.ReactText;

  showError(content: string, actionOptions?: ToastActionOptions): React.ReactText;

  showInfo(content: string, actionOptions?: ToastActionOptions): React.ReactText;

  showSuccessUpdate(): React.ReactText;

  //Download
  showDownloadInfo(downloadOptions: DownloadOptions): React.ReactText;
  updateDownloadInfo(toastId: React.ReactText, downloadOptions: DownloadOptions): React.ReactText;

  notImplemented(): React.ReactText;

  dismiss(toastId: React.ReactText): void;
}

const ToastServiceName = "ToastService";
const getToastService = () => IoC.getLazy<IToastService>(ToastServiceName);

export { ToastServiceName, getToastService };
