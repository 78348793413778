export class Lazy<T> {
  private innerValue?: T;

  private readonly getter: () => T;

  constructor(getter: () => T) {
    this.getter = getter;
  }

  public value = (): T => {
    if (!this.innerValue) {
      this.innerValue = this.getter();
    }
    return this.innerValue;
  };
}
