import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { LoadingPage } from "../LoadingPage/LoadingPage";

export interface ReduxAppProps {
  store: any;
  persistor: any;
  children?: React.ReactNode;
}
const ReduxApp = ({ store, persistor, children }: ReduxAppProps) => {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingPage />} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export { ReduxApp };
