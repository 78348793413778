import React from "react";
import { Redirect, Router, Switch } from "react-router-dom";
import { history } from "./../../../../Configs/history/history";
import { AppRouteType } from "./AppRouteType";

export interface AppRouterProps {
  defaultRoote: string;
  routes: Array<AppRouteType>;
}

const AppRouter: React.FunctionComponent<AppRouterProps> = ({ defaultRoote, routes }: AppRouterProps) => {
  return (
    <Router history={history}>
      <Switch>
        {routes.map(({ id, render: Render, props }) => (
          <Render key={id} {...props} />
        ))}
        <Redirect to={defaultRoote} />
      </Switch>
    </Router>
  );
};

export { AppRouter };
