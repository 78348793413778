import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";

//* Can be usefull for platform context like #ANDROID #IPHONE #IPAD
export interface IPlatformContextValue {
  isMobile: boolean;
}

const DefaultPlatformContextValue: IPlatformContextValue = {
  isMobile: false,
};

const PlatformContext = React.createContext<IPlatformContextValue>(DefaultPlatformContextValue);
PlatformContext.displayName = "PlatformContext";
export const usePlatformContext = (): IPlatformContextValue => React.useContext(PlatformContext);

export const PlatformContextProvider: React.FunctionComponent = ({ children }) => {
  //! breakpoints below XS === 600px
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));
  const platformContextValue: IPlatformContextValue = {
    isMobile,
  };

  return <PlatformContext.Provider value={platformContextValue}>{children}</PlatformContext.Provider>;
};
