import { IconButton } from "@material-ui/core";
import Dialog, { DialogClassKey } from "@material-ui/core/Dialog";
import { ClassNameMap } from "@material-ui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Icons } from "../../Icons/Icons";

import "./CustomDialog.scss";

export interface CustomDialogProps {
  open: boolean;
  titleIntlId?: string;
  onClose: () => void;
  children?: React.ReactNode;
  renderActions?: () => React.ReactNode;
  hideCloseButton?: boolean;
  okAction?: () => void;
  cancelAction?: () => void;
  fullscreen?: boolean;
  classes?: Partial<ClassNameMap<DialogClassKey>> | undefined;
}

const CustomDialog = ({
  open,
  titleIntlId,
  onClose,
  children,
  renderActions,
  hideCloseButton,
  cancelAction,
  okAction,
  fullscreen,
  classes,
}: CustomDialogProps) => {
  return (
    <Dialog
      open={open}
      classes={{ ...classes, paper: classNames("CustomDialog-root", classes?.paper) }}
      fullScreen={fullscreen}>
      <div className="CustomDialog-header">
        {titleIntlId && <FormattedMessage id={titleIntlId} />}
        {!hideCloseButton && (
          <IconButton onClick={onClose} className="CustomDialog-header-closeButton">
            {Icons.Close}
          </IconButton>
        )}
      </div>
      <div className="CustomDialog-body">{children}</div>
      {renderActions ? (
        <div className="CustomDialog-actions">{renderActions()}</div>
      ) : (
        <div className="CustomDialog-actions">
          <div className="CustomDialog-actions-cancel" onClick={cancelAction}>
            <FormattedMessage id="COMMON_CANCEL" />
          </div>
          <div className="CustomDialog-actions-submit" onClick={okAction}>
            <FormattedMessage id="COMMON_VALIDATE" />
          </div>
        </div>
      )}
    </Dialog>
  );
};

export { CustomDialog };
