import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { ConfirmDialogStyle, IDialogService } from "../../Core/Services/Interfaces/IDialogService";
import { ConfirmDialog } from "../Components/Views/CustomDialogs/ConfirmDialog/ConfirmDialog";
import { CustomDialog } from "../Components/Views/CustomDialogs/CustomDialog/CustomDialog";

export class DialogService implements IDialogService {
  public showConfirm = (messageIntlId: string, action: Function, variant?: ConfirmDialogStyle) => {
    this.internalShowDialog(
      <ConfirmDialog
        open={true}
        confirmMessage={<FormattedMessage id={messageIntlId} />}
        onClose={() => this.closeDialog()}
        cancelAction={() => this.closeDialog()}
        okAction={() => this.closeDialog(action)}
      />
    );
  };

  public showDialog = (
    titleIntlId: string,
    messageIntlId: string,
    action: Function,
    children: React.ReactNode,
    renderActions?: () => React.ReactNode
  ) => {
    this.internalShowDialog(
      <CustomDialog
        titleIntlId={titleIntlId}
        open={true}
        children={children}
        onClose={() => this.closeDialog()}
        cancelAction={() => this.closeDialog()}
        okAction={() => this.closeDialog(action)}
        renderActions={renderActions}
      />
    );
  };

  //#region OPEN AND CLOSE

  private internalShowDialog = (content: ReactElement) => {
    if (this.dialogContainer) {
      this.dialogContainer.showDialog(content);
    }
  };

  private closeDialog = (actionToDo?: Function) => {
    if (this.dialogContainer != null) {
      this.dialogContainer.destroyDialog();
      actionToDo && actionToDo();
    }
  };

  private dialogContainer: DialogContainer | null = null;

  public setDialogContainer = (container: DialogContainer): void => {
    if (this.dialogContainer) {
      this.dialogContainer.destroyDialog();
    }
    this.dialogContainer = container;
  };

  //#endregion
}

export interface DialogContainer {
  showDialog: (dialog: React.ReactNode) => void;
  destroyDialog: () => void;
}
