import { FormEvent, useCallback } from "react";

/** 
 * Prevent default on FormEvent. Can be disabled (when busy for ex.)
 */
export const usePreventDefault = (func?: Function, isDisabled: boolean = false) => {
    return useCallback((evt: FormEvent) => {
        evt.preventDefault();
        !isDisabled && func && func();
    }, [func, isDisabled]);
}
