export enum LocalMessageActionType {
  SET_LOCALE_WITH_MESSAGES = "app/i18n/set/locale/with_messages",
  ADD_MESSAGES = "app/i18n/add/messages",
}

export type Messages = { [key: string]: any };

export interface Locale {
  locale: string;
  messages: Messages;
}

export interface SetLocaleWithMessagesAction {
  type: typeof LocalMessageActionType.SET_LOCALE_WITH_MESSAGES;
  payload: { locale: string; messages: Messages };
}

export interface AddMessagesAction {
  type: typeof LocalMessageActionType.ADD_MESSAGES;
  payload: { messages: Messages };
}

export type LocalMessageAction = SetLocaleWithMessagesAction | AddMessagesAction;
