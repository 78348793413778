import DateFnsUtils from "@date-io/date-fns";
import { CssBaseline } from "@material-ui/core";
import { Theme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { fr as frLocale } from "date-fns/locale";
import React from "react";

export interface MuiAppProps {
  children?: React.ReactNode;
  //* Mui Theme
  getMuiTheme: () => Theme;
}

const MuiApp = ({ getMuiTheme, children }: MuiAppProps) => {
  return (
    <React.Fragment>
      <ThemeProvider theme={getMuiTheme()}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <CssBaseline />
          {children}
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export { MuiApp };
