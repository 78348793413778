import { Route } from "react-router";
import { AppRoutes } from "../../../Core/App/AppRoutes";
import { AppRouteType } from "../../Components/Templates/AppRouter/AppRouteType";
import { LegalNoticePage } from "../../Pages/Informations/LegalNotice/LegalNoticePage";
import { CGUPage } from "../../Pages/Informations/LegalNotice/Cgu/CGUPage";
import { LicencesPage } from "../../Pages/Informations/LegalNotice/Licences/LicencesPage";
import { InformationsPage } from "../../Pages/Informations/InformationsPage";
import { AboutPage } from "../../Pages/Informations/About/AboutPage";

const InformationsRoute: AppRouteType = {
  id: AppRoutes.INFORMATIONS,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.INFORMATIONS,
    component: InformationsPage,
  },
};

const AboutRoute: AppRouteType = {
  id: AppRoutes.ABOUT,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.ABOUT,
    component: AboutPage,
  },
};

const LegalNoticeRoute: AppRouteType = {
  id: AppRoutes.LEGAL_NOTICE,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.LEGAL_NOTICE,
    component: LegalNoticePage,
  },
};

const CGURoute: AppRouteType = {
  id: AppRoutes.CGU,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.CGU,
    component: CGUPage,
  },
};

const LicencesRoute: AppRouteType = {
  id: AppRoutes.LICENCES,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.LICENCES,
    component: LicencesPage,
  },
};

export const getInformationsRoutes = () => [InformationsRoute, AboutRoute, LegalNoticeRoute, CGURoute, LicencesRoute];
