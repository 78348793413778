import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { ApiUrls } from "../Models/ApiUrls";
import { NewsDetailsResponse } from "../Models/News";
import { BaseDataService } from "./Base/BaseDataService";

export const NewsDataServiceName: string = "NewsDataService";

export interface INewsDataService {
  getNews: (cts: XHRRequestCanceler) => Promise<NewsDetailsResponse>;
}

export class NewsDataService extends BaseDataService implements INewsDataService {
  public getNews(cts: XHRRequestCanceler): Promise<NewsDetailsResponse> {
    return this.get<NewsDetailsResponse>(ApiUrls.NEWS, cts, { checkError: false }, true);
  }
}
