import { MeState } from "./typings/MeState";
import { defineAbility } from "@casl/ability";

import * as MeActionTypes from "./typings/MeActionTypes";

const initialState: MeState = {
  initialized: false,
  data: undefined,
  ability: defineAbility((): void => {}),
};

export const MeReducer = (state = initialState, action: MeActionTypes.MeAction): MeState => {
  switch (action.type) {
    default:
      return state;
    case MeActionTypes.MeActionType.SET_INITIALIZED:
      return { ...state, initialized: action.payload.initialized };
    case MeActionTypes.MeActionType.SET_INFO:
      return { ...state, data: action.payload.info };
    case MeActionTypes.MeActionType.SET_ABILITY:
      return { ...state, ability: action.payload.ability };
  }
};
