import { ErrorHandlerName, IErrorHandler } from "../../../Libs/xhr/ErrorHandler";
import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { Lazy } from "../../Helpers/Lazy";
import { AuthServiceName, IAuthService } from "../../Services/Authentication/AuthenticationService";
import { IoC } from "../../Services/ServicesContainer";

export interface BaseViewState {
  initialized?: boolean;
  busy?: boolean;
  modified?: boolean;
}

export interface IDisposeViewModel {
  dispose: () => void;
}

abstract class BaseViewModel<T extends BaseViewState> implements IDisposeViewModel {
  public state: T;
  private onStateUpdate?: (newState: T) => void;
  protected readonly authService: Lazy<IAuthService>;
  protected cts?: XHRRequestCanceler;
  protected readonly errorHandler: Lazy<IErrorHandler>;

  constructor(initialState: T, onStateUpdate?: (newState: T) => void) {
    this.state = initialState;
    this.onStateUpdate = onStateUpdate ? onStateUpdate : undefined;
    this.authService = IoC.getLazy(AuthServiceName);
    this.errorHandler = IoC.getLazy<IErrorHandler>(ErrorHandlerName);
  }

  public setStateUpdate = (onStateUpdate: (newState: T) => void): void => {
    this.onStateUpdate = onStateUpdate;
    this.onStateUpdate(this.state);
  };

  protected setState = (newState: T) => {
    this.state = newState;
    if (this.onStateUpdate) {
      this.onStateUpdate(newState);
    }
  };

  public initialize(): void {
    //* Initialize if needed
    this.setState({
      ...this.state,
      initialized: true,
    });
  }

  public dispose(): void {
    this.onStateUpdate = undefined;
    this.disposeManagedObject();
  }

  protected disposeManagedObject(): void {}

  protected getAuthToken = async (): Promise<string> => {
    const token: string = await this.authService.value().getAuthorization();
    return Promise.resolve(token);
  };

  public async WrapError(
    execAction: (cts: XHRRequestCanceler) => Promise<void>,
    execParams?: {
      customErrorHandler?: (error: any) => void;
      withBusy?: boolean;
    }
  ): Promise<void> {
    const { customErrorHandler, withBusy } = execParams ?? {
      customErrorHandler: undefined,
      withBusy: false,
    };

    try {
      this.setState({ ...this.state, busy: withBusy });
      this.cts = new XHRRequestCanceler();
      await execAction(this.cts);
      this.setState({ ...this.state, busy: false });
    } catch (error) {
      this.setState({ ...this.state, busy: false });
      if (customErrorHandler) {
        customErrorHandler(error);
      } else {
        if (!this.errorHandler.value().handle(error)) {
          //TODO: do something like reject error or throw error to caller
        }
      }
    }
  }
}

export { BaseViewModel };
