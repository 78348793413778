export const ApiUrls = {
  ME: "/api/v1/admins/me",

  SECTION: "api/v2/front/sections",
  NEWS: "/api/v1/temporary-news",
  ART_WORKS: "/api/v1/front/artworks",
  ART_WORKS_CATEGORIES: "/api/v1/front/categories",
  ART_WORK: (artworkId: string) => `/api/v1/front/artworks/${artworkId}`,
  ART_WORK_MAP: "/api/v1/front/artworks/maps",
};
