import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DialogServiceName } from "../../../../Core/Services/Interfaces/IDialogService";
import { IoC } from "../../../../Core/Services/ServicesContainer";
import { DialogService } from "../../../Services/DialogService";

export const DialogContainer = () => {
  const [dialog, setDialog] = useState<React.ReactNode>();

  const showDialog = useCallback((dialog: React.ReactNode) => {
    setDialog(dialog);
  }, []);

  const destroyDialog = useCallback(() => {
    setDialog(undefined);
  }, []);

  const dialogService = useMemo(() => IoC.get<DialogService>(DialogServiceName), []);

  useEffect(() => {
    dialogService.setDialogContainer({ showDialog, destroyDialog });
  }, [showDialog, destroyDialog, dialogService]);

  return <>{dialog}</>;
};
