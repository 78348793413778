import { BaseReduxService } from "../Api/BaseReduxService";
import { IoC } from "./../ServicesContainer";
import { Messages } from "./typings/LocalMessageActionTypes";

export interface II18nService {
  getString: (id: string) => string;
  getMessages: () => Messages;
  formatMessage: (id: string, placeholders: Messages) => string;
}

class I18nService extends BaseReduxService implements II18nService {
  public getString = (id: string): string => {
    const messages = this.getMessages();
    return messages[id];
  };

  public getMessages = (): Messages => {
    const messages: { [key: string]: string } = this.getState().I18n.messages;
    return messages;
  };

  //* placeholders is type of { [key: string]: string }
  public formatMessage = (id: string, placeholders: Messages): string => {
    const messages: Messages = this.getMessages();
    let message = messages[id];
    for (var propertyName in placeholders) {
      var re = new RegExp("{" + propertyName + "}", "gm");
      message = message.replace(re, placeholders[propertyName]);
    }
    return message;
  };
}

const I18nServiceName = "I18nServiceName";
const getI18nService = () => IoC.getLazy<II18nService>(I18nServiceName);
export { I18nService, I18nServiceName, getI18nService };
