import { IoC } from "../ServicesContainer";

export interface INavigationService {
  getHomeLink: string;

  goTo: (route: string, replace?: boolean) => void;
  goBack: () => void;
  goToHome: () => void;
  goToLogin: () => void;
  goToOtherSection: () => void;
  goToArtWorkList: (sectionId: string) => void;
  goToArtWorkDetails: (sectionId: string, artworkId: string) => void;
  goToArtWorkMap: (sectionId: string) => void;
  goToInformations: () => void;
  goToAbout: () => void;
  goToLegalNotice: () => void;
  goToCGU: () => void;
  goToLicences: () => void;
}

const NavigationServiceName = "NavigationService";
const getNavigationService = () => IoC.getLazy<INavigationService>(NavigationServiceName);

export { NavigationServiceName, getNavigationService };
