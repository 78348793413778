import { AuthenticationResult } from "../../../Models/Authentication/AuthResponse";

export enum EmailActionType {
  REFRESH_EMAIL_SUCCESS = "app/auth/email/refresh/success",
  REFRESH_EMAIL_FAIL = "app/auth/email/refresh/fail",
  LOGIN_EMAIL_SUCCESS = "app/auth/email/login/success",
  LOGIN_EMAIL_FAIL = "app/auth/email/login/fail",
}

export interface RefreshEmailSuccessAction {
  type: EmailActionType.REFRESH_EMAIL_SUCCESS;
  payload: AuthenticationResult;
}

export interface RefreshEmailFailureAction {
  type: EmailActionType.REFRESH_EMAIL_FAIL;
  payload: { error: any };
}

export interface LoginEmailSuccessAction {
  type: EmailActionType.LOGIN_EMAIL_SUCCESS;
  payload: AuthenticationResult;
}

export interface LoginEmailFailureAction {
  type: EmailActionType.LOGIN_EMAIL_FAIL;
  payload: { error: any };
}

export type EmailAction =
  | RefreshEmailSuccessAction
  | RefreshEmailFailureAction
  | LoginEmailSuccessAction
  | LoginEmailFailureAction;
