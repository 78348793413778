import { AppBootstrapper, BootstrapperInitialized } from "./AppBootstrapper";
import { AppRouter } from "./Web/Components/Templates/AppRouter/AppRouter";
import { AppWrapper } from "./Web/Components/Templates/Wrappers/AppWrapper";
import { PlatformContextProvider } from "./Web/Context/PlatformContext/PlatformContext";
import { ThemeMuiProvider } from "./Web/Themes/ThemeMuiProvider";

const bootstrapper = new AppBootstrapper();
const { store, persistor, routes, defaultRoote, mountActions }: BootstrapperInitialized = bootstrapper.initialize();

function App() {
  return (
    <AppWrapper
      store={store}
      persistor={persistor}
      mountActions={mountActions}
      withEssentialReload
      getMuiTheme={() => ThemeMuiProvider.provideTheme()}
      setupIntl>
      <PlatformContextProvider>
        <AppRouter routes={routes} defaultRoote={defaultRoote} />
      </PlatformContextProvider>
    </AppWrapper>
  );
}

export default App;
