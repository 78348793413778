import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { ApiUrls } from "../Models/ApiUrls";
import { SectionListResponse } from "../Models/Section/Section";
import { BaseDataService } from "./Base/BaseDataService";

export const SectionDataServiceName: string = "SectionDataService";

export interface ISectionDataService {
  getSections: (cts: XHRRequestCanceler) => Promise<SectionListResponse>;
}

export class SectionDataService extends BaseDataService implements ISectionDataService {
  public getSections(cts: XHRRequestCanceler): Promise<SectionListResponse> {
    return this.get<SectionListResponse>(ApiUrls.SECTION, cts, {}, true);
  }
}
