import { Route } from "react-router";
import { AppRoutes } from "../../../Core/App/AppRoutes";
import { AppRouteType } from "../../Components/Templates/AppRouter/AppRouteType";
import { ArtWorkListPage } from "../../Pages/ArtWork/ArtWorkListPage";
import { ArtWorkDetailsPage } from "../../Pages/ArtWork/ArtWorkDetails/ArtWorkDetailsPage";
import { ArtWorkMapPage } from "../../Pages/ArtWork/ArtWorkMap/ArtWorkMapPage";

const artWorkListRoute: AppRouteType = {
  id: AppRoutes.ART_WORK_LIST,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.ART_WORK_LIST,
    component: ArtWorkListPage,
  },
};

const artWorkMapRoute: AppRouteType = {
  id: AppRoutes.ART_WORK_MAP,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.ART_WORK_MAP,
    component: ArtWorkMapPage,
  },
};

const artWorkDetailsRoute: AppRouteType = {
  id: AppRoutes.ART_WORK_DETAILS,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.ART_WORK_DETAILS,
    component: ArtWorkDetailsPage,
  },
};

export const getArtWorkRoutes = () => [artWorkListRoute, artWorkMapRoute, artWorkDetailsRoute];
