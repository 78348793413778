import { IconButton } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import classNames from "classnames";
import { useCallback } from "react";
import { getI18nService } from "../../../../Core/Services/I18n/I18nService";
import { usePreventDefault } from "../../../Hooks/Form/usePreventDefault";
import { Icons } from "../Icons/Icons";
import "./CustomSearch.scss";

export interface SearchProps {
  placeholder?: string;
  inputValue: string;

  onSubmit?: () => void;
  onChange?: (value: string) => void;

  className?: string;
}

const CustomSearch = ({
  placeholder: placeholderProp,
  onSubmit: onSubmitProp,
  inputValue,
  onChange,
  className,
  ...rest
}: SearchProps) => {
  const placeholder = placeholderProp ?? getI18nService().value().getString("COMMON_SEARCH_PLACEHOLDER");
  const onSubmit = usePreventDefault(onSubmitProp);
  const onClear = useCallback(() => {
    onChange && onChange("");
  }, [onChange]);

  return (
    <div className={classNames({ "Search-root": true, [className as string]: className })}>
      <form onSubmit={onSubmit}>
        <FormControl
          className={classNames({
            "Search-formControl": true,
            [className as string]: className ? true : false,
          })}>
          <InputBase
            {...rest}
            className="search-input"
            placeholder={placeholder}
            value={inputValue}
            onChange={(evt) => onChange && onChange(evt.target.value)}
            fullWidth
            endAdornment={
              <div className="Search-endAdornment">
                {inputValue !== "" ? (
                  <IconButton className="Search-EndAdornmentClear" onClick={onClear}>
                    {Icons.Close}
                  </IconButton>
                ) : null}
              </div>
            }
          />
          <IconButton className="Search-button" onClick={onSubmit}>
            {Icons.Search}
          </IconButton>
        </FormControl>
      </form>
    </div>
  );
};

export { CustomSearch };
