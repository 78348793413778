export enum AuthActionType {
    SET_INITIALIZED = 'app/auth/base/set/initialized',
    LOG_OUT = 'app/auth/base/logout',
    SET_AUTHENTICATED = 'app/auth/base/set/isAuthenticated',
    SET_AUTHORIZATION_HEADER = 'app/auth/set/authorization_header',
    CLEAR_AUTHORIZATION_HEADER = 'app/auth/clear/authorization_header',
}

export interface LogoutAction {
    type: AuthActionType.LOG_OUT;
    payload: { reason?: string };
}

export interface SetInitializedAction {
    type: AuthActionType.SET_INITIALIZED;
    payload: { initialized: boolean; reason: string };
}

export interface SetAuthenticatedAction {
    type: AuthActionType.SET_AUTHENTICATED;
    payload: { isAuthenticated: boolean };
}

export interface SetAuthorizationHeaderAction {
    type: AuthActionType.SET_AUTHORIZATION_HEADER;
    payload: { value: string };
}

export interface ClearAuthorizationHeader {
    type: AuthActionType.CLEAR_AUTHORIZATION_HEADER;
}

export type AuthenticationAction =
    | LogoutAction
    | SetInitializedAction
    | SetAuthenticatedAction
    | SetAuthorizationHeaderAction
    | ClearAuthorizationHeader;
