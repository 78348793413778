import { subHours } from "date-fns";
import { addSeconds, parseISO } from "date-fns/esm";

import { AuthenticationResult } from "../../../Models/Authentication/AuthResponse";

export class AuthUtils {
  static getAuthorizationFromToken = (token: string) => `Bearer ${token}`;
  /**
   ** Adds an `expiration_date` as an ISO string
   * @param {Object} auth
   */
  static transformAuth = (auth: AuthenticationResult) => ({
    ...auth,
    expiration_date: addSeconds(new Date(), auth.expires_in).toISOString(),
  });

  static toAuthWithExpirationDate = (auth: AuthenticationResult) => ({
    ...auth,
    expiration_date: addSeconds(new Date(), auth.expires_in).toISOString(),
  });

  /*
   ** @param {Number} expirationDate ISO string
   */
  static authenticationIsExpired = (expirationDate: any) => {
    if (!expirationDate) return true;

    return new Date() > subHours(parseISO(expirationDate), 2);
  };
  static getAuthorizationString: string;
}
