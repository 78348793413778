// import { addLocaleData } from 'react-intl';
// import frLocaleData from 'react-intl/locale-data/fr';

/**
 * @returns {Promise} resolves once react-intl locales and intl are loaded and setup
 */
export const addExtraLocaleData = (): Promise<boolean> => {
  //addLocaleData(frLocaleData);
  // if (!global.Intl) {
  //     return Promise.all(import('intl'), import('intl/locale-data/jsonp/fr.js'));
  // }
  return Promise.resolve(true);
};
