import { ErrorHandlerName, IErrorHandler } from "../Libs/xhr/ErrorHandler";
import { Constants } from "./Constants";
import { ArtWorkDataService, ArtWorkDataServiceName, IArtWorkDataService } from "./DataServices/ArtWorkDataService";
import { ISectionDataService, SectionDataService, SectionDataServiceName } from "./DataServices/SectionDataService";
import { CustomErrorHandler } from "./Errors/CustomErrorHandler";
import { initializeApiClient } from "./Services/Api/ApiService";
import { I18nService, I18nServiceName, II18nService } from "./Services/I18n/I18nService";
import { LogService } from "./Services/LogService";
import { IoC } from "./Services/ServicesContainer";

export abstract class CoreBootstrapper {
  protected initializeCore = (): void => {
    const appErrorHandler = IoC.get<IErrorHandler>(ErrorHandlerName);

    //* Setup Api client
    const timeout = 1000 * 60; //* 1 minute;
    initializeApiClient(Constants.getRoot(), appErrorHandler, timeout);
  };

  protected initializeCoreServices = (): void => {
    //* Create and bind I18nService
    const i18nService: II18nService = new I18nService();
    IoC.bind<II18nService>(I18nServiceName, i18nService);

    //* Create and bind App ErrorHandler
    IoC.bind<IErrorHandler>(ErrorHandlerName, new CustomErrorHandler(i18nService, new LogService()));

    IoC.bind<ISectionDataService>(SectionDataServiceName, new SectionDataService());
    IoC.bind<IArtWorkDataService>(ArtWorkDataServiceName, new ArtWorkDataService());
  };
}
