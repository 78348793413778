import { Canceler } from "axios";

export class XHRRequestCanceler {
  canceler: Canceler | null;
  isCanceled: boolean;

  constructor() {
    this.isCanceled = false;
    this.canceler = null;
  }
  setCanceler = (canceler: Canceler | null) => {
    this.canceler = canceler;
  };

  cancel = (reason?: string): void => {
    if (this.canceler !== null) {
      this.canceler(reason);
      this.canceler = null;
    }
    this.isCanceled = true;
  };
}
